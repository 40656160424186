import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Contact from "../../layouts/Contact/";

const ContactPageEn = () => {
  return (
    <Layout 
    lang="en-CA" 
    switchLink='/nous-parler' 
    slider={false} 
    title="digital signage project" 
    subTitle="Let's talk about your">
      <Seo 
        title="Tell us about your dynamic digital signage project"
        description="Let us show you how Attractif systems facilitate communication with your customers and employees."
       />
      <Contact lang="en-CA" />
    </Layout>
  )
}

export default ContactPageEn
